<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table 
                    striped
                    small
                    id="myTables"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :items="tariffStatuses"
                    :fields="fields"
                    head-row-variant="secondary"
                    >
                    <template #cell(checkbox)="row">
                        <b-form-checkbox
                        v-model="row.detailsShowing"
                        plain
                        class="vs-checkbox-con"
                        @change="row.toggleDetails"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(active)="data">
                            <span v-if="data.item.active== 1">
                                Активен
                            </span>
                            <span v-else>
                                Не активен
                            </span>
                    </template>
                </b-table>
            </b-card>
        </b-col>
        </b-row>
    </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
    data(){
        
        return{
            id: this.$route.query.statuses_id,
            tariffStatuses: [],
            fields: [
            {key: 'checkbox', label: '', thStyle: {width: '25px'}},
            {key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
            // {key: 'price', label: 'Цена', sortable: true, thStyle: {width: '150px'} },
            {key: 'status', label: 'Статус', sortable: true, thStyle: {width: '150px'} },
            {key: 'name', label: 'Наименование', sortable: true, thStyle: {width: '150px'} }
        ],
        }
    },
    methods:{
    },
    mounted(){
        resizeable()
        this.$store.commit('pageData/setdataCount', null)
        this.$http
            .get(`tariffs/${this.id}/edit`)
            .then(res => {
                this.tariffStatuses = res.data.statuses
                this.$store.commit('pageData/setdataCount', this.tariffStatuses.length)
            })
    }
}
</script> 